import { parameters, mapPopupConfig } from "../../../plugnplay/leaf-core";

/*
 * Remove marker when clearing input
 */
export function clearMarker(id) {
    if (id === parameters.inputFromId && window?.leaflet?.markers?.from) {
        window.leaflet.map.removeLayer(window.leaflet.markers.from);
        window.leaflet.markers.from = '';
        window.leaflet.markers.number = window.leaflet.markers.number ?? 0 - 1;

        if (window?.leaflet?.markers?.to && window.leaflet.markers.number > 0) {
            window.leaflet.map.fitBounds([window.leaflet.markers.to._latlng], mapPopupConfig(window.boardWidth));
        }
    }

    if (id === parameters.inputToId && window?.leaflet?.markers?.to) {
        window.leaflet.map.removeLayer(window.leaflet.markers.to);
        window.leaflet.markers.to = '';
        window.leaflet.markers.number = window.leaflet.markers.number ?? 0 - 1;

        if (window?.leaflet?.markers?.from && window.leaflet.markers.number > 0) {
            window.leaflet.map.fitBounds([window.leaflet.markers.from._latlng], mapPopupConfig(window.boardWidth));
        }
    }
};