export * from './core/clearMarker';
export * from './core/reverseMarker';
export * from './core/clearMap';
export * from './core/locationFound';
export * from './core/geolocation';

export function initializeMap(parameters) {
    const center = [parameters.latitude, parameters.longitude];

    return L.map('mapid', {
        center: center,
        zoom: parameters.zoom,
        zoomControl: parameters.zoom_control,
        keyboard: false
    });
}
