import { parameters } from './leaf-core.js';

 /**
 * Add marker on map
 * @param coords
 */
export function addMarker (coords) {
    if (typeof coords !== 'undefined') {
        if(JKisio('img.leaflet-marker-icon').length > 0) {
            JKisio('img.leaflet-marker-icon').remove();
        }
        const icon = parameters.L.icon({
            iconUrl: parameters.urlImages + 'map-marker-from.svg',
            iconSize: parameters.icon_size
        });
        const object_coord = coords.split(';');
        if (typeof object_coord !== 'undefined' && Object.keys(object_coord).length > 1) {
            parameters.L.marker([object_coord[0], object_coord[1]], {icon: icon, keyboard: false}).addTo(window.leaflet.map);
            window.leaflet.map.setView([object_coord[0], object_coord[1]]);
        }
    }
};