export const params = {
    boardContainerId: '#board_container',
    boardContentId: '#board_content',
    boardTitleId: '#board_title_container',
    accordionContent: 'fieldset .ui-accordion-content',
    accordionHeader: 'fieldset .ui-accordion-header',
    desktopBreakPoint: 600,
    accordionHeaderClass: '.ui-accordion-header',
    activeFileds: 'show journey-search-board-modes-selected',
    iconUp: 'ikisio-arrow-drop-up',
    iconDown: 'ikisio-arrow-drop-down',
    arrowClass: '.arrow-ikisio',
    journeySearchContent: '.journey-search-modes .ui-accordion-content',
    fromId: '#search_from_autocomplete',
    fromIdHidden: '#search_from_autocomplete-hidden',
    fromAttr: 'search_from_autocomplete',
    toId: '#search_to_autocomplete',
    toIdHidden: '#search_to_autocomplete-hidden',
    toAttr: 'search_to_autocomplete',
    errorAttr: 'error-message',
    reverseButtonId: '#journey-form-reverse',
    classContent: '.kisio-result',
    formModifyId: '#journey-form-modify',
    formNewSearchId: '#journey-form-new-search',
    scheduleInputId: '#schedule_stop_area_lines_autocomplete',

};