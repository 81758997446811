export function clearMap(geojson_active) {
    if (typeof geojson_active === 'undefined') {
        geojson_active = false;
    }
    //Clear polylines
    if (window.leaflet?.map) {
        for (const i in window.leaflet.map._layers) {
            if (window.leaflet.map._layers[i]._path !== undefined) {
                try {
                    window.leaflet.map.removeLayer(window.leaflet.map._layers[i]);
                } catch (e) {
                    console.log("problem with " + e + window.leaflet.map._layers[i]);
                }
            }
        }

        //clear tooltips and markers
        window.leaflet.map.eachLayer(function (layer) {
            if (layer.options.pane === 'popupPane') {
                layer.removeFrom(window.leaflet.map);
            }
            if ((layer.options.pane === "tooltipPane" || layer.options.pane === 'markerPane') && !geojson_active) {
                layer.removeFrom(window.leaflet.map);
            }
        });
    }
};