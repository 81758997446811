let Highcharts = require('../lib/highcharts');
let Marker  = require('./marker');


export function createChart(element) {
    if (typeof JKisio('index') !== 'undefined' && typeof JKisio('.kisio-detail-street-network #elevation') !== 'undefined' && Kisio.bike_feed == '1') {
        const indexAll = element[0].querySelectorAll(".index-geovelo");
        Array.from(indexAll).forEach( function(selectIndex){
            let index = selectIndex?.dataset.index;
            let data = selectIndex?.dataset.elevation;
            let coords = JSON.parse(selectIndex?.dataset.coordinates);
            if (index != undefined && index != null && data != undefined && data != null) {
                const objFeed = JSON.parse(data);
                let scriptTag = element[0].querySelector("#feed_" + index);
                if (scriptTag.children.length < 1) {
                    let divContainer = document.createElement("div");
                    divContainer.id = "container_" + index;
                    divContainer.style.width = "auto";
                    divContainer.style.minWidth = "300px";
                    divContainer.style.height = "auto";
                    scriptTag.appendChild(divContainer);
                    let obj;
                    if (obj === undefined) {
                         obj = objFeed;
                    }
                    let x = [];
                    let y = [];
                    let z = [];

                    //Get distance into km and elevation
                    for (const element of obj) {
                        x[element.geojson_offset] = (element.distance_from_start) / 1000;
                        x[element.geojson_offset] = Math.round(x[element.geojson_offset] * 100) / 100;
                        y[element.geojson_offset] = element.elevation;
                    }

                    //Fill coords and missing data
                    for (let j = 0; j < coords.coordinates.length; j++) {
                        z[j] = coords.coordinates[j];
                        let prev = j - 1;
                        if (typeof x[j] == "undefined") {
                            x[j] = x[prev];
                        }
                        if (typeof y[j] == "undefined") {
                            y[j] = y[prev];
                        }
                    }
                    Highcharts.chart("container_" + index, {
                        chart: {
                            type: 'area',
                            zoomType: 'x',
                            panning: true,
                            panKey: 'shift',
                        },
                        title: {
                            text: 'Profil du trajet'
                        },
                        xAxis: {
                            categories: x
                        },
                        yAxis: {
                            title: {
                                text: 'Altitude(m)'
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                let object_coord = z[this.point.x];
                                let coords = object_coord[1]+';'+object_coord[0];
                                Marker.addMarker(coords);
                                return 'Distance de <b>' + this.x + '</b> km pour <b>'+ this.y +'</b> m d\'altitude';
                            }
                        },
                        series: [{
                            name: 'Distance(Km)',
                            data: y
                        }],
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: '100%'
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        }
                    });
                }
            }
        });
    }
}
